import { Language } from "@/constants";
import { reactive } from "vue";
import { getTheme } from "@healthyworkers/themes/async";
import { type Theme } from "@healthyworkers/themes";

export const theme: Theme = reactive({
  name: "",
  shortName: "",
  description: "",
  defaultLanguage: Language.en_US,
  supportEmail: "",
  exportPrefix: "",
  termsAndConditions: "#",
  address: "",
  oAuthProviders: [],
  colors: {} as Theme["colors"],
  elementColors: {} as Theme["elementColors"],
  images: {
    logo: {}
  } as Theme["images"],
  fonts: {
    fallback: "sans-serif",
    weights: {
      light: 400,
      medium: 500,
      bold: 600
    }
  } as Theme["fonts"]
});

const domElements: Record<string, HTMLElement | null> = {
  css: null,
  googleFonts: null,
  favicon: null
};

export const setupTheme = async (domain: string = window.location.hostname) => {
  try {
    const themeConfig: Theme = await getTheme(domain);

    updateTheme(themeConfig);
    updateCssVariables(themeConfig);
    updateGoogleFonts(themeConfig.fonts.googleFontsUrl);

    return themeConfig;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

function updateTheme(themeConfig: Theme) {
  if (import.meta.env.VITE_USER_NODE_ENV === "development") {
    if (domElements.favicon) {
      domElements.favicon.setAttribute("href", themeConfig.images.favicon);
    } else {
      domElements.favicon = addElement("link", {
        rel: "icon",
        href: themeConfig.images.favicon,
        type: "image/png"
      });
    }
  }

  Object.assign(theme, themeConfig);
}

function updateCssVariables(themeConfig: Theme) {
  const cssVariables: string[] = [];

  // Colors
  for (const [key, hex] of Object.entries(themeConfig.colors)) {
    cssVariables.push(`--color-${key}: ${hexToRGB(hex)};`);
  }

  // Special element colors
  for (const [key, colorKey] of Object.entries(themeConfig.elementColors)) {
    const hex = themeConfig.colors[colorKey];

    cssVariables.push(`--color-${key}: ${hexToRGB(hex)};`);
  }

  // Font families
  cssVariables.push(`--font-primary: "${themeConfig.fonts.primary}";`);
  cssVariables.push(`--font-fallback: "${themeConfig.fonts.fallback}";`);

  // Font weights
  for (const [key, value] of Object.entries(themeConfig.fonts.weights)) {
    cssVariables.push(`--font-weight-${key}: ${value};`);
  }

  // Add CSS to head
  if (!domElements.css) {
    domElements.css = addElement("style", { type: "text/css" });
  }

  domElements.css.innerHTML = ":root {\n  " + cssVariables.join("\n  ") + "\n}";
}

function updateGoogleFonts(url: string) {
  if (domElements.googleFonts) {
    domElements.googleFonts.setAttribute("href", url);
  } else {
    domElements.googleFonts = addElement("link", {
      rel: "stylesheet",
      href: url
    });
  }
}

function addElement(type: string, attributes: Record<string, string>) {
  const element = document.createElement(type);

  for (const [key, value] of Object.entries(attributes)) {
    element.setAttribute(key, value);
  }

  document.head.appendChild(element);

  return element;
}

function hexToRGB(hex: `#${string}`) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
}
