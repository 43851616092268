import { useAuthStore } from "@/stores/auth";
import { hasAccess, routeMeta } from "@/utils/routing";
import type { RouteLocationRaw, RouteRecordRaw } from "vue-router";
import { AccessLevel } from "@/constants";
import * as api from "@/api";

const UserPreferences = () => import("@/views/UserPreferences.vue");
const UserWelcome = () => import("@/views/UserWelcome.vue");
const UserLogin = () => import("@/views/UserLogin.vue");

const routes: RouteRecordRaw[] = [
  {
    path: "/preferences",
    name: "UserPreferences",
    component: UserPreferences,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.LoggedIn
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/welcome",
    name: "UserWelcome",
    component: UserWelcome,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.LoggedIn
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/login",
    name: "UserLogin",
    component: UserLogin,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Public
    }),
    beforeEnter: [
      hasAccess,
      to => {
        const auth = useAuthStore();

        if (auth.isLoggedIn) {
          const path = to.query.redirect || "/";
          delete to.query.redirect;

          return <RouteLocationRaw>{ path, query: to.query };
        }

        return true;
      }
    ]
  },
  {
    path: "/auth/redirect",
    name: "AuthRedirect",
    component: { render: () => null },
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Public
    }),
    beforeEnter: async () => {
      const auth = useAuthStore();

      if (auth.isLoggedIn) {
        return { name: "BuildingOverview" };
      } else {
        try {
          const redirectPath = sessionStorage.getItem("loginRedirect") || "/";

          return await api
            .getOAuthCredentials()
            .then(({ email, code }) => auth.login(email, code))
            .then(() => ({ path: redirectPath }));
        } catch (err) {
          return {
            name: "UserLogin",
            query: { "oauth-failed": null }
          };
        }
      }
    }
  }
];

export default routes;
