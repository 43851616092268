import type {
  AuthenticateWithEmailAndCodeRequest,
  AuthenticateWithEmailRequest,
  AuthenticateWithEmailResponse,
  GetOAuthCredentialsResponse,
  LoginResponse,
  RefreshTokenRequest
} from "@/types/Authenticate";
import { axios } from "../axios";

export const authenticateByEmail = async (email: string) => {
  return axios
    .post<
      AuthenticateWithEmailResponse,
      AuthenticateWithEmailRequest
    >("api/Auth/authenticate", { email })
    .then(response => response.data);
};

export const authenticateWithEmailAndCode = async (email: string, code: string) => {
  return axios
    .post<LoginResponse, AuthenticateWithEmailAndCodeRequest>("api/Auth/authenticate-with-code", {
      email,
      code
    })
    .then(response => response.data);
};

export const getOAuthCredentials = async () => {
  return axios
    .get<GetOAuthCredentialsResponse>(`${window.location.origin}/v2/authenticate`, {
      withCredentials: true
    })
    .then(response => response.data);
};

export const logout = () => {
  return axios.post<undefined>("api/Auth/logout");
};

export const refreshAuthTokens = async (refreshToken: string) => {
  return axios
    .post<LoginResponse, RefreshTokenRequest>("api/Auth/refresh-token", { refreshToken })
    .then(response => response.data);
};
